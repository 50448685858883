<template>
  <div class="col-md-4 mb-5">
    <div class="steps">
      <div class="card  border-1 border-grey m-1">
        <div class="card-header" style="background: #DBE7F2">
          <h3>{{step.name}}</h3>
        </div>
        <div class="card-body">
          <p class="mt-2">
            Approval condition:
            <b class="approval-condition">
              All
              <i class="fa-solid fa-caret-down"></i>
            </b>
          </p>
          <slot v-for="(user, index) in step.user_conditions ?? []">
            <p class="fw-bolder">{{ user.user_name }}</p>
            <p>Should Approve {{ user.condition_text }}</p>
            <hr>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import {computed} from "vue";

const $props = defineProps({
  step: {
    default: {}
  },
  users: {
    default: {}
  },
  index: {
    type: Number,
    default: 0
  }
})

const userCondition = computed(() => $props.step.user_no_condition.condition)

</script>

<style scoped>

</style>