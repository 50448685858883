<template>
    <div class="me-1 mt-1 p-1 position-relative image-wrapper">
        <img style="object-fit: contain" :src="src" height="95" width="108" alt="">
        <slot name="handler"></slot>
    </div>
</template>

<script>
export default {
    name: 'BlobImageViewer',
    props: ['file'],
    computed: {
        src () {
            return URL.createObjectURL(this.file)
        }
    }
}
</script>

<style scoped>
    .image-wrapper {
        border: 1px solid #d8d6de;
        border-radius: 4px;
    }
</style>
