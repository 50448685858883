<template>
  <div>

    <div class="card">
      <div style="background: #DBE7F2" class="p-2">
        <TitleButton
          btnTitle="Add New"
          :showBtn="true"
          :showSettingBtn="false"
          :showAddNew="false"
          :title="headerTitle"
          @onClickSettingButton="onClickSettingButton"
          @onClickCloseButton="onClickCloseButton"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Supplier</label>
              <input type="text" class="form-control" :value="supplier" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">PO No</label>
              <input type="text" class="form-control" :value="formData.po_number" readonly>
            </div>
            <div class="col-sm-4">
              <label class="align-middle">PO Date</label>
              <input type="date" class="form-control date-mask" v-model="formData.po_date" readonly>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-sm-12">
              <label class="align-middle">Requisition Details:</label>
              <div class="mt-1">
                <template :key="index" v-for="(data, index) in formData.requisition_details">
                  Requisition No: <strong>{{ data.requisition_number }}</strong>, Requisition Date:
                  <strong>{{ data.requisition_date }}</strong>,
                  Requester Name: <strong>{{ data.requester_name }}</strong>, Designation: , Department: .
                  <br>
                </template>
              </div>
            </div>

            <div class="col-sm-12 mt-1">
              <label class="align-middle">CS/Quotation Details:</label>
              <div class="mt-1">
                <template :key="index" v-for="(data, index) in formData.cs_details">
                  CS/Quotation No: <strong>{{ data.cs_number }}</strong>, CS/Quotation Date: <strong>{{
                    data.cs_date
                  }}</strong>.
                  <br>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="px-2">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a  :class="{'active' : formData.has_item_detail, 'disabled' : !formData.has_item_detail}"  class="nav-link"
                data-bs-toggle="tab" aria-controls="home" role="tab" aria-selected="true">Item Details</a>
          </li>
          <li class="nav-item">
            <a :class="{'active' : !formData.has_item_detail, 'disabled' : formData.has_item_detail}"
               data-bs-toggle="tab" aria-controls="profile" role="tab" aria-selected="false">Accounts Details</a>
          </li>
        </ul>
      </div>

      <div class="px-3">
        <div v-if="!formData.has_item_detail">
          <template :key="index" v-for="(data, index) in accountsDetails">
            <ItemDetails
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="false"
            />
          </template>
        </div>

        <div v-else>
          <template :key="index" v-for="(data, index) in itemsDetails">
            <ItemDetails
              class="mt-3"
              :index="index"
              :data="data"
              :products="products"
              :accountHeads="accountHeads"
              :vatRate="vatRate"
              :isItem="true"
            />
          </template>
        </div>
      </div>

      <div class="px-2 mt-3 mb-3">
        <div class="row mt-3 px-2">
          <div class="col-md-7 row justify-content-center">

            <div class="col-lg-7 col-12 text-center border-dashed">
              <div class="d-flex flex-wrap justify-content-between align-middle">
                <BlobImageViewer
                  v-for="(file, index) in formData.additional_images"
                  :file="file"
                  :key="index"
                  :index="index"
                >
                  <template v-slot:handler>
                    <img
                      class="position-absolute cursor-pointer top-0"
                      width="15"
                      src="/app-assets/images/icons/trash.svg"
                      alt=""
                    >
                  </template>
                </BlobImageViewer>
              </div>
              <div class="pt-2">
                <div>
                  <!--TODO: Setup bind functions and remove hidden attribute from input-->
                  <input hidden>
                  <p>Drop the files here ...</p>
                  <p>Drag 'n' drop some additional images</p>
                </div>
                <button @click.prevent="open" class="btn btn-outline-primary">open</button>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <div class="row mt-1">
              <div class="col-md-4 align-middle">SubTotal :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="subTotal" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">VAT :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="totalVAT" readonly>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-4 align-middle">Total :</div>
              <div class="col-md-8">
                <input class="form-control text-end" :value="total" readonly>
              </div>
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="mb-2">
              <label for="note" class="form-label fw-bold">Terms & Conditions:</label>
              <textarea v-model="formData.description"
                        placeholder="Memo"
                        class="form-control"
                        rows="2"
                        id="description"
                        readonly></textarea>
            </div>
          </div>
          <div class="col-lg-12 col-12 mt-2">
            <div class="mb-1">
              <label class="form-label" for="note">Comments</label>
              <textarea
                v-model="comment"
                placeholder="Comments"
                class="form-control"
                rows="1"
                :readonly="viewOnly"
              />
            </div>
          </div>
        </div>

        <div class="row mb-1 d-flex justify-content-end">

          <div
            class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2"
          >
            <slot v-if="!viewOnly">
              <button :disabled="saveButtonLoader|| (formData.approved_by_self > 0)" class="btn btn-danger" @click="changeStatus('rejected')">
                Reject
              </button>

              <button
                :disabled="saveButtonLoader|| (formData.approved_by_self > 0)"
                class="btn btn-primary"
                @click="changeStatus('returned')"
              >
                Return
              </button>

              <button
                :disabled="saveButtonLoader|| (formData.approved_by_self > 0)"
                class="btn btn-success"
                @click="changeStatus((formData.status !== 'pending_approve') ? 'pending_approve' : 'approved')"
              >
                {{getConfirmButtonName()}}
              </button>
            </slot>
            <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
            <button @click="$refs.viewApprovalMatrix.updateShowApprovalMatrix(true)" class="btn btn-warning">Show More</button>
          </div>
        </div>
      </div>
    </div>

    <view-approval-matrix-details
      :workflowDetails="workflowDetails"
      :approvalLogs="formData.workflow_approval_logs ?? []"
      ref="viewApprovalMatrix"
    />
    <GlobalLoader/>
  </div>
</template>

<style>
hr {
  margin: 1rem 0;
  color: #403d4452 !important;
  background-color: currentColor;
  border: 0;
  opacity: 1;
}

.title-background {
  background: #DBE7F2
}
</style>

<script setup>
import TitleButton        from '@/components/atom/TitleButton'
import {
  inject,
  ref,
  onMounted,
  computed,
  watch
}                         from 'vue';
import {
  useRoute,
  useRouter
}                         from "vue-router";
import {useStore}         from 'vuex';
import BlobImageViewer    from '@/components/atom/BlobImageViewer'
import handleRequisitions from "@/services/modules/procurement/requisition";
import handlePurchase     from '@/services/modules/purchase'
import handleContact      from '@/services/modules/contact'
import ItemDetails        from '@/components/molecule/procurement/purchase-order/purchase-order-entry/approval/ItemDetails.vue'
import {vatRate}          from '@/data/inventory.js'
import ViewApprovalMatrixDetails from "@/components/molecule/approval-matrix/ViewApprovalMatrixDetails.vue";
import handleWorkflow from "@/services/modules/approvalMatrix/workflowMatrix";

const router      = useRouter()
const route       = useRoute()
const showError   = inject('showError');
const showSuccess = inject('showSuccess');
const store       = useStore()

const {fetchProductList, fetchAccountHead, ...rest} = handlePurchase()
const {fetchSingleContactProfile}                   = handleContact()
const {
        fetchPONumber,
        fetchCSGeneralData,
        fetchSinglePO,
        updatePOStatus
      } = handleRequisitions();
const {fetchWorkflowFromPageId} = handleWorkflow();

const companyId   = computed(() => {
  return route.params.companyId
})
let headerTitle   = ref('Review PO')
let loader        = ref(false)
let productLoader = ref(false)
let saveButtonLoader = ref(false)
let viewOnly = ref(false)
let products      = ref([])
let accountHeads  = ref([])
let requisition   = ref({})
let supplier      = ref(null)
let user          = ref(null)
let poEditData    = ref({})
let comment    = ref('')
let isPOCreate    = true
let poData        = {
  general: [],
}
const workflowDetails = ref({});
const approvalPageId = route.query.approval_page_id;
const workflowQuery = getQuery() + `&approval_page_id=${approvalPageId}`;
let formData      = ref({
  supplier_id        : null,
  po_number          : null,
  po_date            : null,
  requisition_details: [],
  cs_details         : [],
  subtotal_amount    : 0.00,
  vat_amount         : 0.00,
  total_amount       : 0.00,
  has_item_detail    : true,
  description        : '',
  item_details       : [
    {
      product_id  : null,
      quantity    : null,
      rate        : null,
      amount      : null,
      vat         : null,
      vat_amount  : null,
      total_amount: null,
      description : null,
    }
  ],
  account_details    : [
    {
      account_head_id: null,
      amount         : null,
      vat            : null,
      vat_amount     : null,
      total_amount   : null,
      description    : null,
    }
  ],
})

function onPageReload() {
  loader.value         = true
  const productRes     = fetchProductList(getQuery())
  const accountHeadRes = fetchAccountHead(getQuery())
  if (route.name === 'po-view') {
    viewOnly.value = true;
  }

  Promise.all([
    productRes.then(res => {
      if (res.data) products.value = res.data
    }),
    accountHeadRes.then(res => {
      if (res.data) accountHeads.value = res.data

      if (route.query.poId && route.query.poId !== '' && route.query.poId !== undefined) {
        prepareUpdatePageData();
      } else {
        prepareCreatePageData();
      }
    }),
    fetchWorkflowFromPageId(workflowQuery).then(res => {
      if(res.data) workflowDetails.value = res.data
    })
  ])
      .then(() => {
        loader.value = false;
      })
      .catch((err) => {
        loader.value = false
      })
}

function prepareUpdatePageData(){
  isPOCreate    = false;
  const fetchPO = fetchSinglePO(route.query.poId, getQuery());
  fetchPO.then(res2 => {
    poEditData.value = res2.data;
    setFormData();
  });
}

const getConfirmButtonName = () => {
  if(formData.value.approved_by_self > 0) {
    return 'Already Approved';
  }

  if(formData.value.status === 'pending_approve') {
    return 'Approve';
  }

  return "Confirm"
}

function prepareCreatePageData(){
  const cs_general_items    = {
    cs_general_items: route.query.selected_cs_item
  }
  // Fetch Supplier
  const fetchContactProfile = fetchSingleContactProfile(route.query.supplierId, getQuery());
  fetchContactProfile.then(res => {
    if (res.data) supplier.value = res.data.name;
  });
  // Generate PO number
  const poNumberRes = fetchPONumber(getQuery());
  poNumberRes.then(res => {
    formData.value.po_number = res;
  });
  // Fetch CS Generals
  const fetchCSGenerals = fetchCSGeneralData(cs_general_items, getQuery());
  fetchCSGenerals.then(res => {
    if (res.status) {
      let csgData = res.data;
      delete formData.value.item_details;
      delete formData.value.account_details;
      formData.value.item_details        = [];
      formData.value.account_details     = [];
      formData.value.requisition_details = [];
      formData.value.cs_details          = [];
      csgData.forEach((item) => {
        formData.value.requisition_details.push({
          requisition_number: item.cs_master.requisition_number,
          requisition_date  : new Date(item.cs_master.requisition_date).toISOString().split('T')[0],
          requester_name    : item.cs_master.user.name,
        });
        formData.value.cs_details.push({
          cs_number: item.cs_master.cs_number,
          cs_date  : new Date(item.cs_master.cs_date).toISOString().split('T')[0],
        });

        if (item.is_product == '1') {
          item.product_id = item.requisition_general.product_id;
          item.description = item.item_specification;
          formData.value.item_details.push(item);
        } else {
          item.account_head_id = item.requisition_account.account_head_id;
          item.description = item.item_specification;
          formData.value.account_details.push(item);
        }
      })
      setFormData();
    }
  })
}

function setFormData() {
  if (isPOCreate) {
    formData.value.po_date     = new Date().toISOString().split('T')[0];
    formData.value.supplier_id = route.query.supplierId;
  } else {
    supplier.value             = poEditData.value.contact_profile.full_name;
    formData.value.supplier_id = poEditData.value.contact_profile_id;
    formData.value.po_date     = new Date(poEditData.value.po_date).toISOString().split('T')[0];
    formData.value.po_number   = poEditData.value.po_number;
    formData.value.description = poEditData.value.description;
    formData.value.workflow_approval_logs = poEditData.value.workflow_approval_logs;
    formData.value.approved_by_self = poEditData.value.approved_by_self;
    formData.value.status = poEditData.value.status;

    delete formData.value.item_details;
    delete formData.value.account_details;
    formData.value.item_details        = [];
    formData.value.account_details     = [];
    formData.value.requisition_details = [];
    formData.value.cs_details          = [];
    poEditData.value.purchase_general.forEach((item) => {
      formData.value.requisition_details.push({
        requisition_number: item.cs_general.cs_master.requisition_number,
        requisition_date  : new Date(item.cs_general.cs_master.requisition_date).toISOString().split('T')[0],
        requester_name    : item.cs_general.cs_master.user.name,
      });
      formData.value.cs_details.push({
        cs_number: item.cs_general.cs_master.cs_number,
        cs_date  : new Date(item.cs_general.cs_master.cs_date).toISOString().split('T')[0],
      });

      if (item.is_product == '1') {
        item.product_id = item.cs_general.requisition_general.product_id;
        formData.value.item_details.push(item);
      } else {
        item.account_head_id = item.cs_general.requisition_account.account_head_id;
        formData.value.account_details.push(item);
      }
    })
  }
}

function getQuery() {
  return `?company_id=${route.params.companyId}`;
}

function onClickCloseButton() {
    router.push({name: `po-approval`, params: route.params, query: route.query});
}

function onClickSettingButton() {
  alert('Setting Button clicked');
}

function navigateToListPage() {
  router.push({name: `po-approval`, params: route.params, query: route.query});
}

function changeStatus(status){
  if(status === 'approved' && ! confirm('Are you sure you want to approve this purchase order')){
    return;
  }
  saveButtonLoader.value = true

  let statusData = {
    'status' : status,
    'workflow_master_id' : workflowDetails.value.id,
    'comment' : comment.value,
  }
  updatePOStatus(route.query.poId, getQuery(), statusData)
    .then(res => {
      saveButtonLoader.value = false
      if(res.status) {
        showSuccess(res.message)
        navigateToListPage();
      }
      if(!res.status) {
        showError(res.message)
      }
    })
    .catch(err=> {
      saveButtonLoader.value = false;
      showError(err)
    })
}

const itemsDetails    = computed(() => {
  return formData.value.item_details
})
const accountsDetails = computed(() => {
  return formData.value.account_details
})
const isItem          = computed(() => {
  return !!formData.value.has_item_detail
})
const subTotal        = computed(() => {
  let subTotal = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      subTotal += parseInt(item.quantity * item.rate)
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.amount) {
        subTotal += parseInt(item.amount)
      }
    })
  }

  return subTotal
})
const totalVAT        = computed(() => {
  let vat = 0
  if (formData.value.has_item_detail) {
    formData.value.item_details.map(item => {
      if (item.vat) {
        vat += parseInt((item.vat / 100) * item.quantity * item.rate)
      }
    })
  }
  if (!formData.value.has_item_detail) {
    formData.value.account_details.map(item => {
      if (item.vat) {
        vat += parseInt((item.vat / 100) * item.amount)
      }
    })
  }

  return vat.toFixed(2)
})
const total           = computed(() => {
  return parseInt(subTotal.value) + parseInt(totalVAT.value)
})


onMounted(async () => {
  onPageReload();
})
</script>
