<template>
    <div class="card mt-3" v-if="Object.keys(workflowDetails).length > 0 && showApprovalMatrix">
        <div class="card-header bg-blue-light d-flex justify-content-between">
            <h5 class="card-title">{{ workflowDetails.name }}</h5>
            <button class="btn btn-sm btn-danger" @click="updateShowApprovalMatrix(false)">X</button>
        </div>
        <div class="card-body">
            <div class="row">
                <slot v-if="workflowDetails.approval_steps.length > 0">
                    <view-step
                        v-for="(step, index) in workflowDetails.approval_steps"
                        :key="index"
                        :index="index"
                        :step="step"
                    />
                </slot>
            </div>
            <slot v-if="approvalLogs.length > 0">
                <h3 class="mt-2 text-uppercase">Audit Trail</h3>
                <hr/>
                <div class="container">
                        <log-list
                            v-for="(approvalLog, index) in approvalLogs"
                            :key="index"
                            :index="index"
                            :approvalLog="approvalLog"
                            class="mb-2"
                        />
                </div>
            </slot>
        </div>
    </div>
</template>

<script setup>

import ViewStep from "@/components/molecule/approval-matrix/ViewStep.vue";
import {ref} from "vue";
import LogList from "@/components/molecule/approval-matrix/approval-log/LogList.vue";

const $props = defineProps({
    workflowDetails: {
        type: Object,
        required: true
    },
    approvalLogs: {
        type: Array,
        default: [],
        required: true
    }
})

const showApprovalMatrix = ref(false);

const updateShowApprovalMatrix = show => {
    showApprovalMatrix.value = show;
}

defineExpose({
    updateShowApprovalMatrix,
})

</script>

<style scoped>

</style>